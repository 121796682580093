import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import MainLandingPage from "./Components/MainLandingPage";
import SplashScreen from "./Components/SplashScreen";
import ProtectedRoute from "./Components/ProtectedRoute";
import AdminPortal from "./Components/admin/AdminPortal";
import HomePage from "./Pages/HomePage";
import { Header } from "./Components/Header";
import Footer from "./Components/Footer";
import AboutPage from "./Pages/About";
import Support from "./Pages/Support";
import Resources from "./Pages/Resources";

// Layout for the development site
const MainLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {/* Public landing page - can be toggled*/}
        <Route
          path="/"
          element={
            <ProtectedRoute routeName="mainLandingPage">
              <MainLandingPage />
            </ProtectedRoute>
          }
        />

        {/* Protected development routes (future main site) */}
        <Route
          path="/dev"
          element={
            <ProtectedRoute routeName="devHome">
              <MainLayout>
                <HomePage />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/about"
          element={
            <ProtectedRoute routeName="devAbout">
              <MainLayout>
                <AboutPage />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/support"
          element={
            <ProtectedRoute routeName="devSupport">
              <MainLayout>
                <Support />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dev/resources"
          element={
            <ProtectedRoute routeName="devResources">
              <MainLayout>
                <Resources />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* Protected admin portal - without MainLayout */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute routeName="adminPortal">
              <AdminPortal />
            </ProtectedRoute>
          }
        />

        {/* 404 page */}
        <Route
          path="*"
          element={
            <div className="min-h-screen bg-slate-800 text-white p-8">
              <h1 className="text-4xl">404 - Not Found</h1>
              <p>Path: {location.pathname}</p>
              <Link to="/" className="text-blue-400 hover:underline">
                Go Home
              </Link>
            </div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const [loading, setLoading] = useState(!localStorage.getItem("hasVisited"));

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        localStorage.setItem("hasVisited", "true");
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [loading]);

  return (
    <HelmetProvider>
      <Router>{loading ? <SplashScreen /> : <AppRoutes />}</Router>
    </HelmetProvider>
  );
}

export default App;