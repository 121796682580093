import React from "react";
import { motion } from "framer-motion";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const SponsorsSection = () => {
  const sponsors = ["zwift", "insights", "rapha", "logo4"];

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      className="bg-gray-50 relative overflow-hidden"
      //   style={{
      //     background: `linear-gradient(to bottom, ${colors.backgroundLight}, ${colors.backgroundDark})`,
      //   }}
    >
      <div
        className=" max-w-screen mx-auto py-8 "
        style={{
          background: `linear-gradient(to bottom, ${colors.backgroundLight}, ${colors.backgroundDark})`,
        }}
      >
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Our Partners</h2>
          <div className="mb-4 flex justify-center gap-1 mt-2">
            <div className="w-16 h-0.5 bg-secondary" />
            <div className="w-32 h-0.5 bg-accent" />
            <div className="w-16 h-0.5 bg-secondary" />
          </div>
          <p className="text-white/70 max-w-2xl mx-auto">
            Working together to make a difference in cancer research and
            awareness
          </p>
        </div>
      </div>

      <div className=" max-w-6xl mx-auto px-4 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {sponsors.map((sponsor, index) => (
            <motion.div
              key={sponsor}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ scale: 1.05 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-xl blur-xl group-hover:opacity-100 opacity-0 transition-opacity" />
              <div className="relative bg-white/5 backdrop-blur-sm p-8 rounded-xl border border-white/10 hover:border-white/20 transition-colors">
                <div className="w-32 h-32 relative flex items-center justify-center mx-auto">
                  <img
                    src={`/sponsors/${sponsor}.png`}
                    alt={`${sponsor} logo`}
                    className="max-w-full max-h-full object-contain opacity-70 group-hover:opacity-100 "
                    onError={(e) => {
                      if (e.target.src.endsWith(".png")) {
                        e.target.src = `/sponsors/${sponsor}.svg`;
                      } else if (e.target.src.endsWith(".svg")) {
                        e.target.src = `/sponsors/${sponsor}.jpg`;
                      } else if (e.target.src.endsWith(".jpg")) {
                        e.target.src = `/sponsors/${sponsor}.webp`;
                      }
                    }}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default SponsorsSection;
