import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { getRouteProtectionSettings } from "../firebase/routeProtections";
import { useAuth } from "../hooks/useAuth";

const ProtectedRoute = ({ children, routeName = "mainLandingPage" }) => {
  const { user, loading: authLoading, login } = useAuth(); // Get login from useAuth here
  const [routeProtection, setRouteProtection] = useState(true);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Fetch route protection settings
  useEffect(() => {
    const fetchRouteProtection = async () => {
      try {
        const settings = await getRouteProtectionSettings();
        // If it's the admin portal, always require protection
        setRouteProtection(
          routeName === "adminPortal" ? true : settings[routeName] ?? true
        );
      } catch (error) {
        console.error("Error fetching route protection:", error);
        // Default to protected if there's an error
        setRouteProtection(true);
      } finally {
        setLoading(false);
      }
    };

    fetchRouteProtection();
  }, [routeName]);

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const success = await login(email, password);
      if (!success) {
        setError("Invalid credentials");
      }
    } catch (err) {
      setError(err.message || "An error occurred during login");
    }
  };

  if (loading || authLoading) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  // If route doesn't need protection or user is already authenticated, render children
  if (!routeProtection || user) {
    return children;
  }

  // Show login form if protection is required and user isn't authenticated
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen w-full flex items-center justify-center"
      style={{
        background: `linear-gradient(to bottom right, #0f172a, #1e293b)`,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-md p-6"
      >
        <form
          onSubmit={handleLogin}
          className="bg-white/10 backdrop-blur-sm rounded-lg p-8 shadow-xl"
        >
          <h2 className="text-xl text-white mb-8 font-semibold">
            Protected Content
          </h2>
          {error && (
            <div className="mb-4 text-red-400 text-sm text-center">{error}</div>
          )}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
            required
          />
          <motion.button
            type="submit"
            className="w-full px-4 py-2 bg-[#2494a2] text-white rounded-md"
            whileHover={{ opacity: 0.9 }}
            whileTap={{ scale: 0.98 }}
          >
            Login
          </motion.button>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default ProtectedRoute;