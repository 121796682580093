// src/scripts/initializeSeoSettings.js
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";

const defaultSeoSettings = {
  metaTags: {
    title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
    description:
      "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
    themeColor: "#0f172a",
  },
  openGraph: {
    title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
    description:
      "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
    url: "https://tourde4.com/",
    image: "/TD4-colour.png",
  },
  twitter: {
    title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
    description:
      "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
    image: "/TD4-colour.png",
    card: "summary_large_image",
  },
  googleAnalytics: {
    id: "G-7KERDK7LB0",
    tagManagerId: "GT-5GK56F4K",
  },
};

export const initialiseSeoSettings = async () => {
  try {
    await setDoc(doc(db, "settings", "seo"), defaultSeoSettings);
    console.log("SEO settings initialized successfully");
    return true;
  } catch (error) {
    console.error("Error initializing SEO settings:", error);
    return false;
  }
};
