// src/Components/admin/AdminPortal.js
import React, { useState } from "react";
import { Menu } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Sidebar from "./Sidebar";
import LandingPageContent from "./sections/LandingPageContent";
import SeoSettings from "./sections/SeoSettings";
import RouteProtectionSettings from "./sections/RouteProtectionSettings";

const AdminPortal = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("landing");

  const renderSection = () => {
    switch (activeSection) {
      case "seo":
        return <SeoSettings />;
      case "dashboard":
        return (
          <div>
            <RouteProtectionSettings />
          </div>
        );
      case "landing":
        return <LandingPageContent />;
      case "gallery":
        return <div>Gallery Coming Soon</div>;
      case "settings":
        return <div>Settings Coming Soon</div>;
      default:
        return <LandingPageContent />;
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Desktop Sidebar - always visible on lg screens */}
      <div className="hidden lg:block w-64 flex-shrink-0 bg-gray-900">
        <Sidebar
          isOpen={true}
          setIsOpen={setIsMobileSidebarOpen}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </div>

      {/* Mobile Sidebar - shows/hides based on state */}
      <div className="lg:hidden">
        <AnimatePresence>
          {isMobileSidebarOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/50 z-40"
                onClick={() => setIsMobileSidebarOpen(false)}
              />
              <motion.div
                initial={{ x: -300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ type: "spring", damping: 25, stiffness: 200 }}
                className="fixed inset-y-0 left-0 z-50"
              >
                <Sidebar
                  isOpen={isMobileSidebarOpen}
                  setIsOpen={setIsMobileSidebarOpen}
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                />
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Mobile Header */}
        <div className="lg:hidden bg-white shadow-sm p-4">
          <button
            onClick={() => setIsMobileSidebarOpen(true)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Menu size={24} />
          </button>
        </div>

        {/* Page Content */}
        <motion.main
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1 bg-gray-100 p-4 lg:p-8"
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeSection}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {renderSection()}
            </motion.div>
          </AnimatePresence>
        </motion.main>
      </div>
    </div>
  );
};

export default AdminPortal;
