// src/Components/admin/Sidebar.js
import React from "react";
import {
  LayoutGrid,
  FileText,
  Image,
  Settings,
  X,
  LogOut,
  Cpu,
} from "lucide-react";
import { motion } from "framer-motion";
import { useAuth } from "../../hooks/useAuth"; // Add this import

const Sidebar = ({ isOpen, setIsOpen, activeSection, setActiveSection }) => {
  const { logout } = useAuth(); // Get logout function from useAuth

  const menuItems = [
    { icon: Cpu, label: "SEO Settings", path: "seo" },
    { icon: LayoutGrid, label: "Dashboard", path: "dashboard" },
    { icon: FileText, label: "Landing Page", path: "landing" },
    { icon: Image, label: "Gallery", path: "gallery" },
    { icon: Settings, label: "Settings", path: "settings" },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      // After successful logout, redirect to home
      window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div className="h-full bg-gray-900 text-white p-4 flex flex-col w-64">
      <div className="flex items-center justify-between mb-8">
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-xl font-bold"
        >
          TourDe4 Admin
        </motion.h2>
        <button onClick={() => setIsOpen(false)} className="lg:hidden">
          <X size={24} />
        </button>
      </div>

      <nav className="flex-1">
        <ul className="space-y-2">
          {menuItems.map((item, index) => (
            <motion.li
              key={item.path}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <button
                onClick={() => {
                  setActiveSection(item.path);
                  setIsOpen(false);
                }}
                className={`
                  flex items-center space-x-2 w-full p-2 rounded-lg
                  hover:bg-gray-800 transition-colors
                  ${activeSection === item.path ? "bg-gray-800" : ""}
                `}
              >
                <item.icon size={20} />
                <span>{item.label}</span>
              </button>
            </motion.li>
          ))}
        </ul>
      </nav>

      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
        onClick={handleLogout}
        className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-800 text-red-400"
      >
        <LogOut size={20} />
        <span>Logout</span>
      </motion.button>
    </div>
  );
};

export default Sidebar;
