import React from "react";
import { Mail, ArrowDown, ExternalLink } from "lucide-react";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

// Data
const sponsors = [
  {
    name: "Zwift",
    description:
      "As our Official Virtual Training Partner, Zwift is revolutionizing how Tour De 4 participants prepare for the event. Their innovative virtual cycling platform enables riders worldwide to train together, share experiences, and build the strength needed for the challenge ahead.",
    logo: "/sponsors/zwift.png",
    website: "https://zwift.com",
    images: [
      "/api/placeholder/600/400",
      "/api/placeholder/600/400",
      "/api/placeholder/600/400",
    ],
    features: [
      "Exclusive Tour De 4 training routes",
      "Virtual group rides with Sir Chris Hoy",
      "Structured training programs for all levels",
    ],
  },
  {
    name: "Insights",
    description:
      "Insights brings cutting-edge analytics to Tour De 4, helping us maximize our fundraising impact and reach. Their data-driven approach ensures we can make informed decisions that benefit both participants and charities.",
    logo: "/sponsors/insights.png",
    website: "https://insights.com",
    images: [
      "/api/placeholder/600/400",
      "/api/placeholder/600/400",
      "/api/placeholder/600/400",
    ],
    features: [
      "Real-time fundraising analytics",
      "Participant engagement tracking",
      "Impact measurement tools",
    ],
  },
];

const charities = [
  {
    name: "Cancer Research UK",
    logo: "/sponsors/cruk.png",
    tagline: "Together we will beat cancer",
    description:
      "Cancer Research UK pioneers life-saving research to bring forward the day when all cancers are cured. As the world's largest independent cancer research charity, they conduct research into the prevention, diagnosis and treatment of the disease.",
    impact: [
      { stat: "£2.5M", label: "Raised in 2023" },
      { stat: "120+", label: "Research Projects" },
      { stat: "25,000", label: "Patients Supported" },
    ],
    highlights: [
      "Funding groundbreaking research into stage 4 cancer treatments",
      "Supporting clinical trials across the UK",
      "Developing innovative early detection methods",
    ],
    image: "/cruk-race.png",
    link: "https://www.cancerresearchuk.org",
  },
  {
    name: "Maggie's Centres",
    logo: "/sponsors/maggies.png",
    tagline: "Everyone's home of cancer care",
    description:
      "Maggie's offers free practical and emotional support to people with cancer and their families and friends. Built in the grounds of NHS cancer hospitals, their centres are warm and welcoming places with professional staff on hand to offer support.",
    impact: [
      { stat: "250,000+", label: "People Supported Annually" },
      { stat: "24", label: "Centers Nationwide" },
      { stat: "100%", label: "Free Support" },
    ],
    highlights: [
      "Providing psychological support to cancer patients",
      "Offering practical advice on managing daily life",
      "Creating spaces for sharing experiences and finding strength",
    ],
    image: "/maggies-cycling.jpg",
    link: "https://www.maggies.org",
  },
];

const distributionData = [
  {
    title: "Research",
    amount: "50%",
    desc: "Cancer research initiatives",
  },
  {
    title: "Patient Support",
    amount: "30%",
    desc: "Direct patient care",
  },
  {
    title: "Prevention",
    amount: "20%",
    desc: "Awareness programs",
  },
];

const SponsorCharityPage = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div
        className="h-[50vh] flex items-center justify-center"
        style={{
          background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
        }}
      >
        <div className="max-w-7xl mx-auto px-8 text-center">
          <h1 className="text-7xl font-bold text-white mb-8">Our Partners</h1>
          <p className="text-2xl text-white/70 max-w-3xl mx-auto mb-12">
            Working together to make a difference in the lives of those affected
            by stage 4 cancer.
          </p>
          <ArrowDown className="w-8 h-8 text-white/50 mx-auto animate-bounce" />
        </div>
      </div>

      {/* Individual Partner Sections */}
      {sponsors.map((sponsor, index) => (
        <div
          id="sponsors"
          key={sponsor.name}
          className={`min-h-screen flex items-center ${
            index % 2 === 0 ? "bg-white" : "bg-gray-50"
          }`}
        >
          <div className="max-w-7xl mx-auto px-8 py-24">
            <div className="grid md:grid-cols-2 gap-24 items-center">
              {/* Logo and Description */}
              <div
                className={`space-y-12 ${index % 2 === 1 ? "md:order-2" : ""}`}
              >
                <img
                  src={sponsor.logo}
                  alt={sponsor.name}
                  className="w-full max-w-md mx-auto"
                />
                <div className="space-y-6">
                  <p className="text-xl text-slate-600 leading-relaxed">
                    {sponsor.description}
                  </p>
                  <div className="space-y-3">
                    {sponsor.features.map((feature, i) => (
                      <div key={i} className="flex items-center text-slate-700">
                        <div
                          className="w-2 h-2 rounded-full mr-3"
                          style={{ backgroundColor: colors.secondary }}
                        ></div>
                        {feature}
                      </div>
                    ))}
                  </div>
                  <a
                    href={sponsor.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-8 py-4 rounded-full text-white text-lg transition-all hover:scale-105"
                    style={{ backgroundColor: colors.secondary }}
                  >
                    Visit Website
                    <ExternalLink className="w-5 h-5 ml-2" />
                  </a>
                </div>
              </div>

              {/* Image Gallery */}
              <div
                className={`grid gap-6 ${index % 2 === 1 ? "md:order-1" : ""}`}
              >
                {sponsor.images.map((image, i) => (
                  <div
                    key={i}
                    className={`rounded-2xl overflow-hidden shadow-lg ${
                      i === 0 ? "md:col-span-2" : "md:col-span-1"
                    }`}
                  >
                    <img
                      src={image}
                      alt={`${sponsor.name} showcase ${i + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Charities Section */}
      <div className="bg-white">
        {/* Charities Intro */}
        <div
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
          className="py-32"
        >
          <div className="max-w-7xl mx-auto px-8 text-center">
            <h2 className="text-6xl font-bold text-white mb-8">
              Supporting Charities
            </h2>
            <p className="text-xl text-white/70 max-w-3xl mx-auto">
              Every donation helps fund vital research and support services for
              those affected by cancer. Our charity partners are at the
              forefront of cancer research, treatment, and patient support.
            </p>
          </div>
        </div>

        {/* Individual Charity Sections */}
        <div>
          {charities.map((charity, index) => (
            <div
              key={charity.name}
              className={`py-24 ${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
            >
              <div className="max-w-7xl mx-auto px-8">
                <div className="grid md:grid-cols-2 gap-16 items-center">
                  <div className={index % 2 === 0 ? "" : "md:order-2"}>
                    {/* Logo and Content */}
                    <div className="space-y-8">
                      <img
                        src={charity.logo}
                        alt={charity.name}
                        className="mb-8"
                      />
                      <p className="text-2xl font-medium text-slate-600 italic">
                        "{charity.tagline}"
                      </p>
                      <p className="text-lg text-slate-600 leading-relaxed">
                        {charity.description}
                      </p>

                      {/* Impact Stats */}
                      <div className="grid grid-cols-3 gap-4 py-8">
                        {charity.impact.map((item, i) => (
                          <div key={i} className="text-center">
                            <div
                              className="text-3xl font-bold mb-2"
                              style={{ color: colors.secondary }}
                            >
                              {item.stat}
                            </div>
                            <div className="text-sm text-slate-600">
                              {item.label}
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Highlights */}
                      <div className="space-y-3">
                        {charity.highlights.map((highlight, i) => (
                          <div
                            key={i}
                            className="flex items-center text-slate-700"
                          >
                            <div
                              className="w-2 h-2 rounded-full mr-3"
                              style={{ backgroundColor: colors.secondary }}
                            ></div>
                            {highlight}
                          </div>
                        ))}
                      </div>

                      <a
                        href={charity.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-8 py-4 rounded-full text-white text-lg transition-all hover:scale-105 mt-8"
                        style={{ backgroundColor: colors.secondary }}
                      >
                        Visit {charity.name}
                        <ExternalLink className="w-5 h-5 ml-2" />
                      </a>
                    </div>
                  </div>

                  {/* Image Section */}
                  <div className={index % 2 === 0 ? "" : "md:order-1"}>
                    <div className="relative">
                      <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
                      <img
                        src={charity.image}
                        alt={`${charity.name} impact`}
                        className="relative rounded-2xl shadow-xl w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Distribution Section */}
      <div className="bg-white py-32">
        <div className="max-w-4xl mx-auto px-8">
          <h3 className="text-3xl font-semibold text-slate-800 mb-8 text-center">
            How Funds Are Distributed
          </h3>
          <p className="text-lg text-slate-600 mb-16 text-center">
            100% of all donations are distributed directly to our partner
            charities, with participants able to choose their preferred charity
            during registration.
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            {distributionData.map((item) => (
              <div
                key={item.title}
                className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow p-8 text-center"
              >
                <h4 className="text-xl font-semibold text-slate-800 mb-2">
                  {item.title}
                </h4>
                <p
                  className="text-3xl font-bold mb-3"
                  style={{ color: colors.secondary }}
                >
                  {item.amount}
                </p>
                <p className="text-slate-600">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Become a Sponsor Section */}
      <div
        className="py-32 text-center"
        style={{
          background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
        }}
      >
        <h2 className="text-4xl font-bold text-white mb-8">Become a Partner</h2>
        <p className="text-xl text-white/70 max-w-2xl mx-auto mb-12">
          Join us in our mission to support those affected by stage 4 cancer and
          help make a lasting impact.
        </p>
        <a
          href="mailto:sponsors@tourde4.com"
          className="inline-flex items-center px-12 py-6 rounded-full text-slate-800 font-semibold text-lg transition-all hover:scale-105"
          style={{ backgroundColor: colors.accent }}
        >
          <Mail className="w-6 h-6 mr-3" />
          Get In Touch
        </a>
      </div>
    </div>
  );
};

export default SponsorCharityPage;
