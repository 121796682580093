import React, { useState } from "react";
import {
  Calendar,
  MapPin,
  Users,
  CheckCircle,
  Clock,
  Bike,
  Heart,
  ChevronRight,
  Train,
  Hotel,
  Plane,
  Car,
} from "lucide-react";

// Custom Card Components
const Card = ({ children, className = "" }) => (
  <div className={`relative rounded-lg p-6 ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="mb-4">{children}</div>;

const CardTitle = ({ children }) => (
  <h3 className="text-xl font-semibold">{children}</h3>
);

const CardContent = ({ children, className = "" }) => (
  <div className={className}>{children}</div>
);

const DecorativeSection = ({ children, className = "" }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
    <div className={`relative ${className}`}>{children}</div>
  </div>
);
const ResourcesPage = () => {
  const [selectedTab, setSelectedTab] = useState("participants");

  const colors = {
    primaryDark: "#304b78",
    primaryLight: "#435e95",
    secondary: "#2494a2",
    accent: "#f3d678",
    backgroundDark: "#0f172a",
    backgroundLight: "#1e293b",
  };

  const trainingDates = [
    { date: "June 1, 2024", time: "9:00 AM BST", type: "Endurance Ride" },
    { date: "June 8, 2024", time: "10:00 AM BST", type: "Hill Training" },
    { date: "June 15, 2024", time: "9:00 AM BST", type: "Recovery Ride" },
  ];

  const renderContent = () => {
    switch (selectedTab) {
      case "participants":
        return (
          <div className="space-y-12">
            {/* Zwift Training Section */}
            <DecorativeSection>
              <section
                id="zwift-training"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <div className="flex items-center mb-6">
                  <img
                    src="/sponsors/zwift.png"
                    alt="Zwift Logo"
                    className="mr-4 max-h-12"
                  />
                  <h2 className="text-3xl font-bold">Training with Zwift</h2>
                </div>
                <p className="text-gray-600 mb-8">
                  Join our official training partner Zwift for structured
                  training sessions designed specifically for Tour De 4
                  participants.
                </p>

                <div className="grid gap-6 md:grid-cols-3">
                  {trainingDates.map((session, index) => (
                    <Card key={index} className="bg-gray-50">
                      <CardContent className="pt-6">
                        <div className="flex items-start space-x-4">
                          <Calendar className="w-5 h-5 text-secondary flex-shrink-0" />
                          <div>
                            <p className="font-semibold">{session.date}</p>
                            <p className="text-sm text-gray-600">
                              {session.time}
                            </p>
                            <p className="text-sm text-secondary mt-1">
                              {session.type}
                            </p>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </section>
            </DecorativeSection>

            {/* Training Plans */}
            <section
              id="training-plans"
              className="bg-gradient-to-br from-background-light to-background-dark text-white rounded-xl p-8"
            >
              <h2 className="text-3xl font-bold mb-6">Training Plans</h2>
              <div className="grid md:grid-cols-2 gap-8">
                <Card className="bg-white/10 backdrop-blur">
                  <CardHeader>
                    <CardTitle className="text-xl text-white">
                      Full Route Training Plan
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-3">
                      {[
                        "12-week structured program",
                        "Endurance building",
                        "Hill climbing focus",
                        "Recovery strategies",
                      ].map((item, index) => (
                        <li key={index} className="flex items-center">
                          <CheckCircle className="w-4 h-4 mr-2 text-accent" />
                          <span className="text-white/90">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
                <Card className="bg-white/10 backdrop-blur">
                  <CardHeader>
                    <CardTitle className="text-xl text-white">
                      Half Route Training Plan
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-3">
                      {[
                        "8-week preparation",
                        "Base fitness building",
                        "Technique focus",
                        "Nutrition guidance",
                      ].map((item, index) => (
                        <li key={index} className="flex items-center">
                          <CheckCircle className="w-4 h-4 mr-2 text-accent" />
                          <span className="text-white/90">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </section>

            {/* Additional Resources Grid */}
            <section
              id="additional-resources"
              className="grid md:grid-cols-3 gap-6"
            >
              {[
                {
                  title: "Equipment Checklist",
                  icon: Bike,
                  items: [
                    "Bike maintenance",
                    "Required gear",
                    "Recommended spares",
                  ],
                },
                {
                  title: "Nutrition Guide",
                  icon: Heart,
                  items: [
                    "Pre-ride meals",
                    "During ride nutrition",
                    "Recovery foods",
                  ],
                },
                {
                  title: "Safety Guidelines",
                  icon: CheckCircle,
                  items: [
                    "Road safety rules",
                    "Emergency contacts",
                    "Weather preparation",
                  ],
                },
              ].map((resource, index) => (
                <Card
                  key={index}
                  className="hover:shadow-lg transition-shadow bg-gray-50"
                >
                  <CardHeader>
                    <resource.icon className="w-8 h-8 text-secondary mb-2" />
                    <CardTitle>{resource.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-2">
                      {resource.items.map((item, i) => (
                        <li key={i} className="text-gray-600 flex items-center">
                          <ChevronRight className="w-4 h-4 text-secondary mr-2" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </section>
          </div>
        );

      case "supporters":
        return (
          <div className="space-y-12">
            <DecorativeSection>
              <section
                id="volunteer-opportunities"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <h2 className="text-3xl font-bold mb-8">
                  Support the Tour De 4
                </h2>
                <div className="grid md:grid-cols-2 gap-8">
                  <Card className="bg-gray-50 shadow">
                    <CardHeader>
                      <Users className="w-8 h-8 text-secondary mb-2" />
                      <CardTitle>Volunteering Opportunities</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-3">
                        {[
                          "Route marshaling",
                          "Feed station support",
                          "Registration desk",
                          "First aid support",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex items-center text-gray-600"
                          >
                            <CheckCircle className="w-4 h-4 mr-2 text-secondary" />
                            {item}
                          </li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>

                  <Card className="bg-gray-50 shadow">
                    <CardHeader>
                      <MapPin className="w-8 h-8 text-secondary mb-2" />
                      <CardTitle>Cheering Stations</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-3">
                        {[
                          "Official viewing points",
                          "Recommended times",
                          "Parking information",
                          "Facilities available",
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="flex items-center text-gray-600"
                          >
                            <CheckCircle className="w-4 h-4 mr-2 text-secondary" />
                            {item}
                          </li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                </div>
              </section>
            </DecorativeSection>
          </div>
        );

      case "travel":
        return (
          <div className="space-y-12">
            <DecorativeSection>
              <section
                id="travel"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <h2 className="text-3xl font-bold mb-8">
                  Getting to Tour De 4
                </h2>
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                  {[
                    {
                      icon: Plane,
                      title: "By Air",
                      items: [
                        "Nearest airports",
                        "Airport transfers",
                        "Flight recommendations",
                        "Bike transport guidelines",
                      ],
                    },
                    {
                      icon: Train,
                      title: "By Train",
                      items: [
                        "Main stations",
                        "Cycle carriage info",
                        "Transfer services",
                        "Group booking options",
                      ],
                    },
                    {
                      icon: Car,
                      title: "By Road",
                      items: [
                        "Parking facilities",
                        "Route planning",
                        "Electric charging",
                        "Bike rack requirements",
                      ],
                    },
                    {
                      icon: MapPin,
                      title: "Local Transport",
                      items: [
                        "Shuttle services",
                        "Taxi companies",
                        "Bike transport",
                        "Local bus routes",
                      ],
                    },
                  ].map((transport, index) => (
                    <Card key={index} className="bg-gray-50">
                      <CardHeader>
                        <transport.icon className="w-8 h-8 text-secondary mb-2" />
                        <CardTitle>{transport.title}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <ul className="space-y-2">
                          {transport.items.map((item, i) => (
                            <li
                              key={i}
                              className="text-gray-600 flex items-center"
                            >
                              <ChevronRight className="w-4 h-4 text-secondary mr-2" />
                              {item}
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </section>
            </DecorativeSection>

            <DecorativeSection>
              <section
                id="accommodation"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <h2 className="text-3xl font-bold mb-8">Where to Stay</h2>
                <div className="grid md:grid-cols-3 gap-8">
                  {[
                    {
                      title: "Partner Hotels",
                      description:
                        "Special rates at our partner accommodations",
                      features: [
                        "Bike storage facilities",
                        "Early breakfast options",
                        "Group booking discounts",
                        "Maintenance area access",
                      ],
                    },
                    {
                      title: "Local B&Bs",
                      description: "Charming local accommodation options",
                      features: [
                        "Family-run venues",
                        "Cyclist-friendly",
                        "Local knowledge",
                        "Flexible check-in",
                      ],
                    },
                    {
                      title: "Alternative Options",
                      description: "Other accommodation choices",
                      features: [
                        "Camping facilities",
                        "Holiday cottages",
                        "Glamping sites",
                        "Group hostels",
                      ],
                    },
                  ].map((option, index) => (
                    <Card key={index} className="bg-gray-50">
                      <CardHeader>
                        <Hotel className="w-8 h-8 text-secondary mb-2" />
                        <CardTitle>{option.title}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-gray-600 mb-4">
                          {option.description}
                        </p>
                        <ul className="space-y-2">
                          {option.features.map((feature, i) => (
                            <li
                              key={i}
                              className="text-gray-600 flex items-center"
                            >
                              <CheckCircle className="w-4 h-4 text-secondary mr-2" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </section>
            </DecorativeSection>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div
        id="resources-hero"
        className="relative py-24 px-6 bg-gradient-to-br from-background-dark to-background-light"
      >
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-bold text-white mb-4">Resources</h1>
          <p className="text-xl text-white/80 max-w-2xl">
            Everything you need to prepare for and support the Tour De 4 event,
            whether you're participating or cheering from the sidelines.
          </p>
        </div>
      </div>

      {/* Tab Navigation */}
      <div id="resources-nav" className="bg-white shadow-lg sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex space-x-8 -mb-px">
            {[
              { id: "participants", label: "Participants" },
              { id: "supporters", label: "Supporters" },
              { id: "travel", label: "Travel & Accommodation" },
            ].map((tab) => (
              <button
                key={tab.id}
                onClick={() => setSelectedTab(tab.id)}
                className={`py-4 px-1 border-b-2 font-medium text-lg transition-colors ${
                  selectedTab === tab.id
                    ? `border-secondary text-secondary`
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Content Sections */}
      <div className="max-w-7xl mx-auto px-6 py-12">{renderContent()}</div>
    </div>
  );
};

export default ResourcesPage;
