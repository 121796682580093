// src/Components/admin/sections/SeoSettings.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { db } from "../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { initialiseSeoSettings } from "../../../scripts/initialiseSeoSettings";
import SocialCardPreview from "../components/SocialCardPreview";

const SeoSettings = () => {
  const [seoData, setSeoData] = useState({
    metaTags: {
      title: "",
      description: "",
      themeColor: "#0f172a",
    },
    openGraph: {
      title: "",
      description: "",
      url: "https://tourde4.com/",
      image: "",
    },
    twitter: {
      title: "",
      description: "",
      image: "",
      card: "summary_large_image",
    },
    googleAnalytics: {
      id: "",
      tagManagerId: "",
    },
  });

  const validateMetaContent = (content, type) => {
    const limits = {
      title: 70,
      description: 200,
    };

    return {
      isValid: content.length <= limits[type],
      remaining: limits[type] - content.length,
      isTooLong: content.length > limits[type],
    };
  };

  const [saveStatus, setSaveStatus] = useState("");
  const [activeTab, setActiveTab] = useState("general");

  useEffect(() => {
    loadSeoData();
  }, []);

  const loadSeoData = async () => {
    try {
      const docRef = doc(db, "settings", "seo");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setSeoData(docSnap.data());
      }
    } catch (error) {
      console.error("Error loading SEO data:", error);
    }
  };

  const handleSave = async () => {
    try {
      setSaveStatus("Saving...");
      await setDoc(doc(db, "settings", "seo"), seoData);
      setSaveStatus("Saved successfully!");
      setTimeout(() => setSaveStatus(""), 3000);
    } catch (error) {
      setSaveStatus("Error saving changes");
      console.error("Error saving SEO data:", error);
    }
  };

  const updateMetaData = (section, field, value) => {
    setSeoData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">SEO & Metadata Settings</h1>

      {/* Tab Navigation */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {["general", "openGraph", "twitter", "analytics"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`py-4 px-1 border-b-2 font-medium text-sm
                ${
                  activeTab === tab
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      {/* Settings Panels */}
      <div className="bg-white rounded-lg shadow p-6">
        {/* General Meta Tags */}
        {activeTab === "general" && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Page Title
              </label>
              <input
                type="text"
                value={seoData.metaTags.title}
                onChange={(e) =>
                  updateMetaData("metaTags", "title", e.target.value)
                }
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Meta Description
              </label>
              <textarea
                value={seoData.metaTags.description}
                onChange={(e) =>
                  updateMetaData("metaTags", "description", e.target.value)
                }
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Theme Color
              </label>
              <input
                type="color"
                value={seoData.metaTags.themeColor}
                onChange={(e) =>
                  updateMetaData("metaTags", "themeColor", e.target.value)
                }
                className="mt-1 block rounded-md border border-gray-300 p-1"
              />
            </div>
          </motion.div>
        )}

        {/* Open Graph Settings */}
        {activeTab === "openGraph" && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                OG Title
              </label>
              <input
                type="text"
                value={seoData.openGraph.title}
                onChange={(e) =>
                  updateMetaData("openGraph", "title", e.target.value)
                }
                className={`mt-1 block w-full rounded-md border ${
                  validateMetaContent(seoData.openGraph.title, "title")
                    .isTooLong
                    ? "border-red-300"
                    : "border-gray-300"
                } p-2`}
              />
              <div className="mt-1 text-sm">
                {
                  validateMetaContent(seoData.openGraph.title, "title")
                    .remaining
                }{" "}
                characters remaining
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                OG Description
              </label>
              <textarea
                value={seoData.openGraph.description}
                onChange={(e) =>
                  updateMetaData("openGraph", "description", e.target.value)
                }
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                OG Image URL
              </label>
              <input
                type="text"
                value={seoData.openGraph.image}
                onChange={(e) =>
                  updateMetaData("openGraph", "image", e.target.value)
                }
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>

            {/* Facebook Preview */}
            <div className="mt-8 space-y-6">
              <h3 className="text-lg font-medium text-gray-900">
                Facebook Preview
              </h3>
              <div className="bg-[#f0f2f5] p-6 rounded-lg">
                <SocialCardPreview
                  platform="facebook"
                  data={seoData.openGraph}
                />
              </div>
              <div className="text-sm text-gray-500">
                <p>Best practices for Facebook:</p>
                <ul className="list-disc ml-5">
                  <li>Image ratio: 1.91:1</li>
                  <li>Recommended image size: 1200 x 630 pixels</li>
                  <li>Title: 70 characters</li>
                  <li>Description: 200 characters</li>
                </ul>
              </div>
            </div>
          </motion.div>
        )}

        {/* Twitter Settings */}
        {activeTab === "twitter" && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Twitter Title
              </label>
              <input
                type="text"
                value={seoData.twitter.title}
                onChange={(e) =>
                  updateMetaData("twitter", "title", e.target.value)
                }
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Twitter Description
              </label>
              <textarea
                value={seoData.twitter.description}
                onChange={(e) =>
                  updateMetaData("twitter", "description", e.target.value)
                }
                rows={3}
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Twitter Image URL
              </label>
              <input
                type="text"
                value={seoData.twitter.image}
                onChange={(e) =>
                  updateMetaData("twitter", "image", e.target.value)
                }
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>

            {/* Twitter Preview */}
            <div className="mt-8 space-y-6">
              <h3 className="text-lg font-medium text-gray-900">
                Twitter Preview
              </h3>
              <div className="bg-[#f7f9f9] p-6 rounded-lg">
                <SocialCardPreview platform="twitter" data={seoData.twitter} />
              </div>
              <div className="text-sm text-gray-500">
                <p>Best practices for Twitter:</p>
                <ul className="list-disc ml-5">
                  <li>Image ratio: 2:1</li>
                  <li>Recommended image size: 1200 x 600 pixels</li>
                  <li>Title: 70 characters</li>
                  <li>Description: 200 characters</li>
                </ul>
              </div>
            </div>
          </motion.div>
        )}

        {/* Analytics Settings */}
        {activeTab === "analytics" && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Google Analytics ID
              </label>
              <input
                type="text"
                value={seoData.googleAnalytics.id}
                onChange={(e) =>
                  updateMetaData("googleAnalytics", "id", e.target.value)
                }
                placeholder="G-XXXXXXXXXX"
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Google Tag Manager ID
              </label>
              <input
                type="text"
                value={seoData.googleAnalytics.tagManagerId}
                onChange={(e) =>
                  updateMetaData(
                    "googleAnalytics",
                    "tagManagerId",
                    e.target.value
                  )
                }
                placeholder="GT-XXXXXXXX"
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
          </motion.div>
        )}

        {/* Save Buttons */}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={initialiseSeoSettings}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Initialize Default SEO
          </button>
          <button
            onClick={() => loadSeoData()}
            className="px-4 py-2 border rounded hover:bg-gray-50"
          >
            Reset
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save Changes
          </button>
          {saveStatus && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="self-center text-sm text-gray-600"
            >
              {saveStatus}
            </motion.p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeoSettings;
