// Components/admin/sections/RouteProtectionSettings.js
import { useState, useEffect } from "react";
import {
  updateRouteProtection,
  getRouteProtectionSettings,
} from "../../../firebase/routeProtections";

const RouteProtectionSettings = () => {
  const [settings, setSettings] = useState({
    mainLandingPage: false,
    adminPortal: true,
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      const currentSettings = await getRouteProtectionSettings();
      setSettings(currentSettings);
    };
    loadSettings();
  }, []);

  const handleToggle = async (route) => {
    try {
      setIsSaving(true);
      const newSettings = {
        ...settings,
        [route]: !settings[route],
      };
      await updateRouteProtection(newSettings);
      setSettings(newSettings);
    } catch (error) {
      console.error("Error updating route protection:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Route Protection Settings</h2>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="font-medium">Main Landing Page</h3>
            <p className="text-sm text-gray-500">
              Control access to the main landing page
            </p>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={settings.mainLandingPage}
              onChange={() => handleToggle("mainLandingPage")}
              disabled={isSaving}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all" />
          </label>
        </div>

        <div className="flex items-center justify-between opacity-50">
          <div>
            <h3 className="font-medium">Admin Portal</h3>
            <p className="text-sm text-gray-500">Always protected</p>
          </div>
          <label className="relative inline-flex items-center">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={true}
              disabled={true}
            />
            <div className="w-11 h-6 bg-blue-600 rounded-full peer after:content-[''] after:absolute after:top-0.5 after:right-[2px] after:bg-white after:rounded-full after:h-5 after:w-5" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default RouteProtectionSettings;
