// firebase/routeProtection.js
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const ROUTE_SETTINGS_DOC = "settings/routeProtection";

export const getRouteProtectionSettings = async () => {
  const docRef = doc(db, ROUTE_SETTINGS_DOC);
  const docSnap = await getDoc(docRef);
  return docSnap.exists()
    ? docSnap.data()
    : {
        mainLandingPage: false, // default to not protected
        adminPortal: true, // always protected
      };
};

export const updateRouteProtection = async (settings) => {
  const docRef = doc(db, ROUTE_SETTINGS_DOC);
  await setDoc(docRef, {
    ...settings,
    adminPortal: true, // ensure admin portal is always protected
  });
};
