import React from "react";
import { motion } from "framer-motion";

const SplashScreen = ({ finishLoading }) => {
  return (
    <motion.div
      className="fixed inset-0 w-full h-full flex items-center justify-center bg-[#0f172a] z-50"
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 2.5 }}
      onAnimationComplete={finishLoading}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          duration: 0.5,
          ease: "easeOut",
        }}
      >
        <motion.img
          src="/TD4-colour.png"
          alt="Tour De Four Logo"
          className="w-64 h-64 object-contain"
          animate={{
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.5, 1],
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default SplashScreen;
