import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CookieConsentBanner from "./Components/CookieConsentBanner";
import { AuthProvider } from './hooks/useAuth';

// Create root
const container = document.getElementById("root");
const root = createRoot(container);

const isDev = process.env.NODE_ENV === 'development';

const log = (...args) => {
  if (isDev) {
    console.log(...args);
  }
};

const logError = (...args) => {
  if (isDev) {
    console.error(...args);
  }
};

// Render app
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
      <CookieConsentBanner />
    </AuthProvider>
  </React.StrictMode>
);

// Update service worker registration
serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    log("Service Worker registration successful");
  },
  onUpdate: (registration) => {
    log("New content is available; please refresh.");
  },
  onError: (error) => {
    logError("Error during service worker registration:", error);
  },
});
